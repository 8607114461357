import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Shopping from "../components/shopping";

function Products({ data }) {
  const { nodes } = data.allShopifyProduct;

  return (
    <Layout>
      <SEO title="Our Products" />
      <Shopping data={nodes} />
    </Layout>
  );
}
export default Products;

export const query = graphql`
  {
    allShopifyProduct {
      totalCount
      nodes {
        id
        description
        handle
        storefrontId
        shopifyId
        productType
        title
        variants {
          availableForSale
          price
        }
      }
    }
  }
`;
